<template>
  <v-overlay :value="true" :opacity="1" color="alabaster">
    <v-progress-linear indeterminate style="width: 100vw" color="primary" :height="2" :rounded="true"/>
  </v-overlay>
</template>

<script>
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import store from '@/store';
  import localize from '@/filters/localize';

  export default {
    name: "Auth",

    async mounted() {
      methods.clearUserData();
      await this.wpLogin();
    },

    methods: {
      queryData() {
        let queryData = {};
        this.$router.currentRoute.query.userName && (queryData.user_name = this.$router.currentRoute.query.userName);
        this.$router.currentRoute.query.customerId && (queryData.customer_id = this.$router.currentRoute.query.customerId);
        this.$router.currentRoute.query.emailAddress && (queryData.email = this.$router.currentRoute.query.emailAddress);
        return queryData;
      },
      async wpLogin() {
        try {
          let userData = await api.wpLogin(this.queryData());
          this.$store.commit('changeUserData', userData.data);
          localStorage.setItem('userData', JSON.stringify(userData.data));
          if (userData.data.locale) {
            store.commit('changeLocale', userData.data.locale.toLowerCase());
            localStorage.setItem('locale', userData.data.locale.toLowerCase());
          }
          methods.showGlobalSnackbar('success', `${localize('hello')} ${userData.data.name}`);
          this.$router.push({name: 'home'});
        } catch (e) {
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      }
    }
  };
</script>
